/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #cf5a16;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://use.typekit.net/val2vrk.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  font-family: montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.wrapper {
  max-width: 1140px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  flex: 1 0 auto;
}

.brand {
  font-weight: bold;
  font-size: 22px;
  line-height:52px;
}
.brand span{
  display:inline-block;
  min-width:100px;
}

.site-header {
  position: relative;
  background-color: #fff;
}

.site-header__wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.site-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.nav__wrapper {
  display: flex;
}

.nav__item a {
  display: block;
  padding: 1.5rem 1rem;
  font-weight: 700;
}

.hero {
  margin-top: 100px;
}
.profile-pic img {
  display: block;
  margin: 0 auto;
}
.spline-watermark{
  position: relative !important;
  display:none !important;
}
.face-image {
  position: relative;
  display: block;
  width:250px;
  height:250px;
  margin: 0 auto;
}
.face-image img {
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.face-image img.active {
  z-index: 1;
}
h1 {
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 20px;
}
h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom:10px;
}
.subhead {
  font-size: 18px;
  line-height: 26px;
  margin-bottom:20px;
}
.emoji{
  font-size:19px;
}
.row ul {
  list-style: circle;
  line-height: 28px;
  padding-left: 20px;
}
.row ul li {
  padding-left: 5px;
}
.row ul li li{
  margin-left: 15px;
}
.section {
  margin-top: 100px;
}

.case {
  display: block;
  margin-bottom: 50px;
  padding: 25px;
  color: #fff;
}
.case p {
  line-height: 26px;
}

.strategy {
  background-color: #006251;
}
.innovation {
  background-color: #91c46d;
}
.ux {
  background-color: #eeeae0;
  color: #000;
}

.readmore {
  background: #000;
  color: #fff;
  padding: 15px;
  font-weight: 700;
  display: inline-block;
  margin-top: 60px;
}

.readmore:hover {
  color: #000;
  background-color: #fff;
}

.button {
  background: #000;
  color: #fff;
  padding: 15px;
  font-weight: 700;
  display: inline-block;
}
.button:hover {
  color: #000;
  background-color: #fff;
}
.about .button {
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
}

p.text {
  line-height: 26px;
  text-align: justify;
  margin-bottom:10px;
}
.subheading {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top:15px;
}
.sidenote {
  text-align: center;
  padding: 15px 0 5px 0;
}
footer {
  display: block;
  background: #f9f9f9;
  font-size: 14px;
  font-weight: 700;
  margin-top: 100px;
}
.extra li a:hover {
  opacity: 0.6;
  padding-bottom: 10px;
}
.marginbottom{
  margin-bottom:25px;
}
.footer {
  flex-shrink: 0;
  max-width: 1140px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  text-align: left;
  min-height: 60px;
  line-height: 60px;
  vertical-align: center;
}
.footer a:hover {
  opacity: 0.8;
}
.footer ul li {
  display: inline-block;
  margin-right: 20px;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 26px;
    line-height:40px;
  }
  h2{
    font-size:22px;
    line-height:32px;
  }
  .hero{
    margin-top:40px;
  }
  .case{
    padding:20px;
    margin-bottom:20px;
  }
  .readmore{
    margin-top:20px;
    width:50%;
    text-align:center;
  }
  .button{
    width:100%;
    text-align:center;
  }
  .section{
    margin-top:40px;
  }
  footer{
    line-height:40px;
    margin-top:40px;
  }
}